import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Home(){
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    
    return (
        <Container>
            <Row>
            <h1>Home</h1>
            <p>This is a test web site used to test and showcase various technical elements mostly related to identity management in Guernsey.</p>
            <p>As such it is presented as example only, do not trust the information shown here. Site allows Guernsey community to set up their accounts, log in, get various perks and also get verified credentials issued to them.</p>
            <p>Note again that as this is a test site, use at your own risk.</p>
            </Row>
            <AuthenticatedTemplate>
            <Row>
                {activeAccount ? (
                    <Link to="/token">Check your token</Link>
                ) : ""}
            </Row>
            </AuthenticatedTemplate>
        </Container>
    );
};