import { TokenClaims } from "@azure/msal-common";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { tokenRequest } from "../authConfig";
import { CardType } from "./CardType";

export interface ICardType {
    id: string;
    name: string;
    manifestUrl: string;
    display: ICardDisplay;
}

export interface ILogo {
	description: string;
	image: string;
	uri: string;
}

export interface ICard {
	backgroundColor: string;
	description: string;
	issuedBy: string;
	textColor: string;
	title: string;
	logo: ILogo;
}

export interface IConsent {
	instructions: string;
	title: string;
}

export interface IClaim {
	claim: string;
	label: string;
	type: string;
}

export interface ICardDisplay {
	locale: string;
	card: ICard;
	consent: IConsent;
	claims: IClaim[];
}

export function CardTypes () {
    const { instance } = useMsal();
    const [cardTypes, setCardTypes] = useState<ICardType[]>([]);
    const activeAccount = instance.getActiveAccount();
    
    useEffect(()=>{
        instance.acquireTokenSilent(tokenRequest).then(result => {
            fetch(process.env.REACT_APP_API_URL + "/card-types", {
                method: 'GET',
                headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${result.accessToken}`} 
            })    
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    setCardTypes(json.cardTypes as ICardType[]);
                })
        })
    }, [])

    const renderCardTypes = () => {
        return cardTypes.map(cardType => {
            let isFamilyUser = false;
            let isGuernseyUser = false;

            if (activeAccount?.idTokenClaims){
                isFamilyUser = isPromotedUser(activeAccount?.idTokenClaims);
                console.log("Promoted user")
                
                const email = getEmail(activeAccount?.idTokenClaims);
                if (email)
                    isGuernseyUser =  email.endsWith('.gg') || email.endsWith('wplatel@outlook.com') || email.endsWith('aldisv@hotmail.com');
            }

            if (cardType.name.endsWith("Guernsey Resident"))

            // grant issuance rights for Family member card only to family users identified by checking token
            // grant issuance to test Guernsey resident card for those having je e-mail address
            return <CardType
                id={cardType.id} 
                key={cardType.id} 
                cardType={cardType} 
                canIssue={(isGuernseyUser && cardType.name.endsWith("Guernsey Resident"))}/>;
        });
      };

    return (
        <>
        <h2>Available Card Types</h2>
        <Accordion defaultActiveKey="0">
            <>{renderCardTypes()}</>
        </Accordion>
        </>
    );
}

function isPromotedUser(tokenClaims: (TokenClaims & { [key: string]: unknown; })): boolean {
    let isPromoted = false;
    
    Object.keys(tokenClaims).forEach((key) => {
        switch (key) {
            case 'family_name':
                if (tokenClaims[key] === "Vilums" || tokenClaims[key] === "Viluma" || tokenClaims[key] === "Viļuma" ||  tokenClaims[key] === "Viļums")
                    isPromoted = true;
                break;
            case 'emails':
                (tokenClaims[key] as string[]).find(item => {
                    if (item.endsWith("@vilumi.com"))
                        isPromoted = true;
                })
                break;
            default:
                break;
            }
    });

    return isPromoted;
}

function getEmail(token: (TokenClaims & { [key: string]: unknown; })): string | undefined {
    let email = undefined;
    
    Object.keys(token).forEach((key) => {
        switch (key) {
            case 'emails':
                email = (token[key] as string[]).length > 0 ? (token[key] as string[])[0] : undefined;
        }
    });

    return email;
};